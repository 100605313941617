
<template>
  <div class="templateClass">
    <div class="templateCentreContent">
      <el-card class="box-card">
        <div slot="header" style="position: relative">
          <div class="query">
            <el-select v-model="bodyData.timeType" placeholder="时间类型">
              <el-option label="采样时间" value="1"> </el-option>
              <el-option label="报告时间" value="2"> </el-option>
              <el-option label="创建时间" value="3"> </el-option>
            </el-select>

            <el-date-picker
              style="width: 250px"
              v-model="timeArr"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getDataList(true)"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-select v-model="bodyData.keyWordType" placeholder="搜搜类型">
              <el-option label="姓名" value="1"> </el-option>
              <el-option label="样本Id" value="2"> </el-option>
            </el-select>
            <el-input
              clearable
              placeholder="根据搜索类型输入"
              v-model="bodyData.keyWord"
              @keyup.enter.native="getDataList(true)"
            ></el-input>
            <el-input
              clearable
              placeholder="请输入用户 Id"
              v-model="bodyData.userId"
              @keyup.enter.native="getDataList(true)"
            ></el-input>

            <el-button type="success" class="ml10" @click="getDataList(true)"
              >查询</el-button
            >

            <el-button type="primary" @click="addOrEditPopup()">新增</el-button>
            <Import ref="Import" @refreshDataList="getDataList(true)"></Import>
          </div>
        </div>

        <el-table
          :data="listData"
          v-loading="loading"
          element-loading-text="正在拼命加载中"
          element-loading-spinner="el-icon-loading"
          border
          stripe
        >
          <el-table-column
            type="index"
            :index="1"
            label="#"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="sampleId"
            label="Sample ID"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="sex"
            label="性别"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="dnaBiologicalAge"
            label="DNA生物学年龄"
            min-width="140"
          ></el-table-column>
          <el-table-column prop="birthday" label="出生日期" min-width="120">
            <template slot-scope="scope">
              <p>{{ scope.row.birthday | dateFormat2 }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="agingIndex"
            label="衰老指数"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="dnaLevelThreshold"
            label="您DNA水平超过"
            min-width="140"
          >
            <template slot-scope="scope">
              <p>{{ Math.round(scope.row.dnaLevelThreshold * 100) }}%</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="metabolismIndex"
            label="代谢指数为"
            min-width="140"
          >
          </el-table-column>

          <el-table-column
            prop="dnaBiologicalAgeRatio"
            label="DNA生物学年龄比"
            min-width="150"
          >
            <template slot-scope="scope">
              <p>{{ Math.round(scope.row.dnaBiologicalAgeRatio * 100) }}%</p>
            </template></el-table-column
          >
          <el-table-column label="采样时间" min-width="160">
            <template slot-scope="scope">
              <p>{{ scope.row.samplingTime | dateFormat1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="报告时间" min-width="160">
            <template slot-scope="scope">
              <p>{{ scope.row.reportTime | dateFormat1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" min-width="160">
            <template slot-scope="scope">
              <p>{{ scope.row.createTime | dateFormat1 }}</p>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            prop="operation"
            label="操作"
            width="295px;"
          >
            <template slot-scope="scope">
              <div class="tableOperation">
                <el-button
                  type="text"
                  @click="
                    $router.push({
                      name: 'EPISDetails',
                      query: {
                        id: scope.row.id,
                      },
                    })
                  "
                  >查看报告</el-button
                >
                <span class="operationLine">|</span>
                <el-button type="text" @click="addOrEditPopup(scope.row.id)"
                  >编辑</el-button
                >
                <span class="operationLine">|</span>
                <el-button class="F56C6C" type="text" @click="del(scope.row.id)"
                  >删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <el-pagination
          class="floatRight"
          v-if="total > 10"
          @size-change="getDataListSizeChange"
          @current-change="getDataListCurrentChange"
          :current-page.sync="bodyData.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="bodyData.size"
          :total="total"
          layout="total,sizes, prev, pager, next"
        ></el-pagination>
      </el-card>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-edit ref="addOrEdit" @refreshDataList="getDataList()"></add-or-edit>
  </div>
</template>

<script>
import AddOrEdit from "./EPISListTemplate/AddOrEdit";
import Import from "./EPISListTemplate/Import";
export default {
  components: {
    AddOrEdit,
    Import,
  },
  data() {
    return {
      loading: false,
      listData: [],
      total: 0,
      timeArr: [],
      bodyData: {
        size: 10,
        current: 1,
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    /**
     * 处理数据中的数组日期
     */
    handleBodyData() {
      if (this.timeArr && this.timeArr.length > 0) {
        this.$set(this.bodyData, "startTime", this.timeArr[0]);
        this.$set(this.bodyData, "endTime", this.timeArr[1]);
      } else {
        this.$set(this.bodyData, "startTime", "");
        this.$set(this.bodyData, "endTime", "");
      }
    },
    /**
     * 新增或修改弹窗
     */
    addOrEditPopup(id) {
      this.$nextTick(() => {
        this.$refs.addOrEdit.init(id);
      });
    },
    /**
     * 删除数据
     */
    del(id) {
      if (id) {
        this.$confirm("是否删除所选择的数据?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http
              .delete(`db-sample-data/baseDeleteById/${id}`)
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList(true);
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    /**
     * 加载列表数据数据
     * @flag {boolean} 是否设置current为1
     */
    getDataList(flag) {
      if (flag) this.bodyData.current = 1;
      this.loading = true;
      if (this.getUserRoleName().indexOf("管理员") == -1)
        this.$set(this.bodyData, "userId", this.getUserId());
      this.handleBodyData();
      this.$http
        .post(`db-sample-data/getSelectPage`, this.bodyData)
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data) {
              this.listData = data.records;
              this.total = data.total;
            }
            this.loading = false;
          }
        });
    },
    getDataListSizeChange(val) {
      this.bodyData.size = val;
      this.getDataList(true);
    },
    getDataListCurrentChange(val) {
      this.bodyData.current = val;
      this.getDataList();
    },
  },
};
</script>
